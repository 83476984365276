import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { hasSubItems } from 'redux/modules/trolley/selectors/get-item-sub-products';
import getIsLoadingSubs from 'redux/modules/trolley/selectors/get-loading-subs';
import SubProductPod from '../SubProductPod';
import styles from './index.scss';

const UnavailableProduct = ({ lineNumber, trolleyItemId }) => {
  const hasSubs = useSelector(state => hasSubItems(state, lineNumber));
  const isLoadingSubs = useSelector(getIsLoadingSubs);
  return (
    <section>
      <header className={styles.header}>
        <Typography element="h2" styleAs="paragraphHeading">
          Unavailable Item
        </Typography>
      </header>
      <SubProductPod
        lineNumber={lineNumber}
        isUnavailable
        isRemovable={!hasSubs && !isLoadingSubs}
        trolleyItemId={trolleyItemId}
      />
    </section>
  );
};

UnavailableProduct.displayName = 'UnavailableProduct';

UnavailableProduct.propTypes = {
  lineNumber: PropTypes.string,
  trolleyItemId: PropTypes.number,
};

UnavailableProduct.defaultProps = {
  lineNumber: null,
  trolleyItemId: null,
};

export default memo(UnavailableProduct);
