/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';

import getIsLoadingSubs from 'redux/modules/trolley/selectors/get-loading-subs';
import { getSubProductsByLineNumber } from 'redux/modules/trolley/selectors/get-item-sub-products';

import SubProductPod from '../SubProductPod';

import styles from './index.scss';

const ProductsList = memo(({ sortedArray }) => {
  if (sortedArray.length > 0) {
    return (
      <ul className={styles.productsWrapper}>
        {sortedArray.map(({ lineNumber }) => {
          return <SubProductPod lineNumber={lineNumber} key={lineNumber} />;
        })}
      </ul>
    );
  }
  return (
    <div className={styles.noSubsMessage}>
      <Typography element="h2" styleAs="sectionHeading">
        Unfortunately we have no alternatives to show you
      </Typography>
    </div>
  );
});

const SubsProductList = ({ lineNumber: subProductLineNumber }) => {
  const substitutionProducts = useSelector(state =>
    getSubProductsByLineNumber(state, subProductLineNumber),
  );
  const isLoadingSubs = useSelector(getIsLoadingSubs);

  // As per UI team request we should display 10 item at most and for that reason it makes sense
  // to display the 10 item with the best score (most appropriate substitution products), highest score on top
  const sortedArray = useMemo(() => {
    return substitutionProducts
      .sort((itemA, itemB) => (itemA.score < itemB.score ? 1 : -1))
      .slice(0, 10);
  }, [substitutionProducts]);

  return (
    <section>
      <header className={styles.header}>
        <Typography element="h2" styleAs="paragraphHeading">
          Alternatives
        </Typography>
      </header>
      {isLoadingSubs ? (
        <div className={styles.spinnerWrapper}>
          <Spinner size="large" isActive />
        </div>
      ) : (
        <ProductsList sortedArray={sortedArray} />
      )}
    </section>
  );
};

SubsProductList.displayName = 'SubsProductList';

SubsProductList.propTypes = {
  lineNumber: PropTypes.string,
};

SubsProductList.defaultProps = {
  lineNumber: null,
};

export default memo(SubsProductList);
