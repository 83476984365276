import { swapUnavailableModal } from 'constants/modals';
import { getNextConflictTrolleyItem } from 'redux/modules/conflicts/selectors';
import { getSubstitutionProducts } from 'redux/modules/trolley/actions/get-substitution-products';
import { toggleSwapUnavailableProductModal } from 'redux/modules/trolley/actions/toggle-swap-unavailable-product-modal';
import { notifyModalClosed } from 'redux/modules/common-modal/actions';
import { clearSwapedItems } from 'redux/modules/trolley/actions/clear-swaped-items';

const fetchNextUnavailableTrolleyItemSubs = lineNumber => (dispatch, getState) => {
  const { lineNumber: nextLineNumber, trolleyItemId } = getNextConflictTrolleyItem(
    getState(),
    lineNumber,
  );

  if (lineNumber === nextLineNumber) {
    toggleSwapUnavailableProductModal()(dispatch, getState);
    clearSwapedItems()(dispatch, getState);
    notifyModalClosed({
      ...swapUnavailableModal,
      title: swapUnavailableModal.titleText,
    })(dispatch, getState);
  } else {
    toggleSwapUnavailableProductModal(nextLineNumber, trolleyItemId, true)(dispatch, getState);
    getSubstitutionProducts(nextLineNumber, 'next_item_btn')(dispatch, getState);
  }
};

export default fetchNextUnavailableTrolleyItemSubs;
