// import { trackRemoveUnavailableItemsFromTrolley } from 'analytics/remove-unavailable-items-from-trolley';
import { getSubProductsByLineNumber } from 'redux/modules/trolley/selectors/get-item-sub-products';
import { getItemByLineNumber } from 'redux/modules/trolley/selectors/get-item';
import { SET_UNAVAILABLE_ITEM_FOR_REMOVAL } from './types';

export function setUnavailableItemForRemoval(lineNumber, trolleyItemId) {
  return async (dispatch, getState) => {
    const state = getState();

    const subProducts = getSubProductsByLineNumber(state, lineNumber);
    const productInTrolley = subProducts.find(
      product => !!getItemByLineNumber(state, product.lineNumber),
    );
    if (productInTrolley) {
      dispatch({
        type: SET_UNAVAILABLE_ITEM_FOR_REMOVAL,
        trolleyItemId,
      });
    }
  };
}
