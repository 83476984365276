/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';

import { swapUnavailableModal } from 'constants/modals';

import { getSwapUnavailableProductModal } from 'redux/modules/trolley/selectors/get-swap-unavailable-product-modal';
import { getSwappableItemsLength } from 'redux/modules/conflicts/selectors';
import fetchNextUnavailableTrolleyItemSubs from 'redux/modules/trolley/actions/fetch-next-unavailable-trolley-item-subs';
import { toggleSwapUnavailableProductModal } from 'redux/modules/trolley/actions/toggle-swap-unavailable-product-modal';
import { clearSwapedItems } from 'redux/modules/trolley/actions/clear-swaped-items';
import { setUnavailableItemForRemoval } from 'redux/modules/trolley/actions/set-unavailable-item-for-removal';
import { notifyModalClosed } from 'redux/modules/common-modal/actions';
import SubsProductList from './SubsProductList';
import UnavailableProduct from './UnavailableProduct';

import styles from './index.scss';

const ModalButton = memo(({ trolleyHardConflictLength, onCloseModal, onNextClick }) => {
  if (trolleyHardConflictLength === 1) {
    return (
      <Button
        className={styles.button}
        data-testid="done-btn"
        aria-label="Close Modal"
        onClick={onCloseModal}
        label="Done"
        theme="finalising"
      />
    );
  }
  return (
    <Button
      className={styles.button}
      data-testid="next-btn"
      aria-label="Go to next unavailable item"
      onClick={onNextClick}
      label="Next item"
      theme="finalising"
    />
  );
});

const SwapUnavailableProductModal = () => {
  const { isOpen, lineNumber, trolleyItemId } = useSelector(getSwapUnavailableProductModal);
  const trolleyHardConflictLength = useSelector(getSwappableItemsLength);
  const dispatch = useDispatch();

  const onCloseModal = useCallback(() => {
    dispatch(setUnavailableItemForRemoval(lineNumber, trolleyItemId));
    dispatch(toggleSwapUnavailableProductModal());
    dispatch(clearSwapedItems());
    dispatch(
      notifyModalClosed({
        ...swapUnavailableModal,
        title: swapUnavailableModal.titleText,
      }),
    );
  }, [dispatch, lineNumber, trolleyItemId]);

  const onNextClick = useCallback(() => {
    dispatch(setUnavailableItemForRemoval(lineNumber, trolleyItemId));
    dispatch(fetchNextUnavailableTrolleyItemSubs(lineNumber));
  }, [lineNumber, trolleyItemId, dispatch]);

  return (
    <Modal
      className={styles.modal}
      closeModal={onCloseModal}
      isOpen={isOpen}
      titleText="SWAP"
      contentClassName={styles['modal-content']}
    >
      <>
        <UnavailableProduct lineNumber={lineNumber} trolleyItemId={trolleyItemId} />
        <SubsProductList lineNumber={lineNumber} />
        <section className={styles.buttonWrapper}>
          <ModalButton {...{ trolleyHardConflictLength, onCloseModal, onNextClick }} />
        </section>
      </>
    </Modal>
  );
};

export default memo(SwapUnavailableProductModal);
