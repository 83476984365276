/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, memo } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMakeSelector } from 'hooks/use-make-selector';
import useTrolleyActions from 'hooks/use-trolley-actions';

import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { Close } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import Thumbnail from 'components/Images/Thumbnail';
import TrolleyControls from 'components/TrolleyActions/TrolleyControlsV2';
import ProductSize from 'components/Product/Size';
import ProductPrice from 'components/Product/Price';
import ProductTags from 'components/ProductTags';
import PromotionElement from 'components/Product/Promotion';
import { TROLLEY_TEXT } from 'components/ProductPod/TrolleyControls/constants';
import Null from 'components/Null';
import { BREAKPOINTS } from 'constants/grid';
import { SSRMediaQuery } from 'components/SSRMediaQuery';

import removeItemFromTrolley from 'redux/modules/trolley/actions/remove-item-from-trolley';
import fetchNextUnavailableTrolleyItemSubs from 'redux/modules/trolley/actions/fetch-next-unavailable-trolley-item-subs';
import { makeGetProductByLineNumber } from 'redux/modules/entities/selectors/products';
import { makeGetPromotionsByIds } from 'redux/modules/entities/selectors/promotions';
import { getPDPUrl } from 'utils/product';
import { getClickContext } from 'utils/clickContext';
import { handleProductListClick } from 'utils/gtm';
import { weightOptions } from 'constants/weightOptions';

import styles from './index.scss';

const SEPARATOR = ' | ';
const EMPTY_ARRAY = [];

const PodButtons = memo(
  ({
    isRemovable,
    isUnavailable,
    lineNumber,
    optimisticTrolleyQuantityAmount,
    productName,
    onClickAdd,
    onClickRemove,
  }) => {
    if (isRemovable) {
      return (
        <div className={styles.removeBtnContainer}>
          <Button
            data-testid="remove-btn"
            aria-label={`Remove ${productName} from trolley`}
            startIcon={<Close size="small" />}
            onClick={onClickRemove}
            theme="primaryWhite"
            width="fit"
          />
        </div>
      );
    }
    if (!isUnavailable) {
      if (optimisticTrolleyQuantityAmount > 0) {
        return (
          <div className={styles.trolleyControllsContainer}>
            <TrolleyControls lineNumber={lineNumber} productName={productName} />
          </div>
        );
      }
      return (
        <div className={styles.trolleyControllsContainer}>
          <Button
            aria-label={`Select ${productName} for replacement`}
            data-testid="swap-btn"
            label="Select"
            onClick={onClickAdd}
            width="full"
          />
        </div>
      );
    }
    return null;
  },
);

const SubProductPod = ({ lineNumber, isUnavailable, isRemovable, trolleyItemId }) => {
  const dispatch = useDispatch();
  const {
    brand,
    brandName,
    categories,
    currentSaleUnitPrice,
    displayPriceEstimated,
    displayPriceQualifier,
    id: productId,
    name: productName,
    productTags,
    productType,
    promotions,
  } = useMakeSelector(makeGetProductByLineNumber, lineNumber) ?? {};
  const productPromotions = useMakeSelector(makeGetPromotionsByIds, promotions);
  const {
    desiredAmount,
    desiredUom,
    addToTrolley,
    optimisticTrolleyQuantityAmount,
    optimisticTrolleyQuantityUom,
  } = useTrolleyActions({ lineNumber, productId });

  const uomDisplay = weightOptions[optimisticTrolleyQuantityUom]?.label ?? '';

  const allergenTags = productTags?.filter(tag => tag.name === 'Allergen update') ?? EMPTY_ARRAY;

  const pdpUrl = useMemo(() => getPDPUrl(productId, productName), [productId, productName]);
  const OptionalLink = pdpUrl ? Link : Null;

  const productLinkHandler = useCallback(() => {
    handleProductListClick({
      productData: {
        brand: brand || brandName,
        categories,
        currentSaleUnitPrice,
        id: productId,
        name: productName,
        productType,
        promotion: {
          promotionDescription: productPromotions
            .map(({ promotionDescription } = {}) => promotionDescription)
            .filter(description => description)
            .join(SEPARATOR),
        },
      },
    });
  }, [
    productId,
    productName,
    productPromotions,
    productType,
    currentSaleUnitPrice,
    categories,
    brand,
    brandName,
  ]);

  const onClickRemove = useCallback(() => {
    dispatch(fetchNextUnavailableTrolleyItemSubs(lineNumber));
    dispatch(removeItemFromTrolley({ trolleyItemId }));
  }, [lineNumber, trolleyItemId, dispatch]);

  const onClickAdd = useCallback(
    event => {
      addToTrolley({
        quantity: {
          amount: desiredAmount,
          uom: desiredUom,
        },
        clickContext: getClickContext(event),
      });
    },
    [addToTrolley, desiredAmount, desiredUom],
  );

  if (!productId) return null;

  return (
    <li
      className={classNames(styles.product, {
        [styles.unavailableProduct]: isUnavailable,
      })}
      key={lineNumber}
      data-test-trolley-pod-name={productName}
    >
      <div
        className={classNames(styles.productInfo, {
          [styles.unavailableProductInfo]: isUnavailable,
        })}
      >
        <div className={styles.thumbnailWrapper}>
          <OptionalLink
            data-testid="link-pdp"
            to={pdpUrl}
            aria-label={productName}
            target="_blank"
            rel="noreferrer"
            onClick={productLinkHandler}
          >
            <Thumbnail itemId={productId} name={productName} disableTooltip />
          </OptionalLink>
        </div>
        <div className={styles.infoContainer}>
          <ProductTags productTagsOverride={allergenTags} />
          <OptionalLink
            className={styles.titleLink}
            data-testid="link-pdp-title"
            to={pdpUrl}
            target="_blank"
            rel="noreferrer"
            onClick={productLinkHandler}
          >
            <Typography
              styleAs="paragraphHeading"
              className={styles.productTitle}
              data-testId="product-name"
            >
              {productName}
              <ProductSize productId={productId} additionalClasses={styles.typicalWeight} />
            </Typography>
          </OptionalLink>
          <SSRMediaQuery maxWidth={BREAKPOINTS.lg}>
            {promotions?.length > 0 && (
              <div className={styles.promotions} data-test="trolley-pod-promotion">
                {promotions.map(promotionId => (
                  <PromotionElement
                    {...{
                      key: `${productId}_${promotionId}`,
                      miniPromo: true,
                      productId,
                      promotionId,
                      showIcon: false,
                      isTrolley: true,
                    }}
                  />
                ))}
              </div>
            )}
            <div
              data-testid="product-pod-prices-section"
              className={classNames(styles.prices, {
                [styles.unavailablePrice]: isUnavailable,
              })}
            >
              <span data-test="product-pod-price" className={styles.itemPriceSection}>
                <span className="sr-only">{TROLLEY_TEXT.itemPrice}</span>
                <Typography styleAs="sectionHeading" className={styles.price} fixedSize>
                  <ProductPrice productId={productId} />
                  {displayPriceEstimated && (
                    <span className={styles.priceEst}>
                      {` ${TROLLEY_TEXT.each}`}
                      <abbr title="estimated">{` ${TROLLEY_TEXT.estimated}`}</abbr>
                    </span>
                  )}
                </Typography>
                {displayPriceQualifier && (
                  <>
                    <span className="sr-only">{TROLLEY_TEXT.pricePer}</span>
                    <Typography styleAs="paragraph" className={styles.pricePerUnit}>
                      {/* removing parenthesis temporary */}
                      {displayPriceQualifier.split(/[()]/).join('')}
                    </Typography>
                  </>
                )}
              </span>

              {isUnavailable && (
                <Typography styleAs="paragraph" noMargins>
                  {`${TROLLEY_TEXT.qty}: ${optimisticTrolleyQuantityAmount}${uomDisplay}`}
                </Typography>
              )}
            </div>
          </SSRMediaQuery>
          <SSRMediaQuery minWidth={BREAKPOINTS.lg}>
            {promotions?.length > 0 && (
              <div className={styles.promotions} data-test="trolley-pod-promotion">
                {promotions.map(promotionId => (
                  <PromotionElement
                    {...{
                      key: `${productId}_${promotionId}`,
                      miniPromo: true,
                      productId,
                      promotionId,
                      showIcon: false,
                      isTrolley: true,
                    }}
                  />
                ))}
              </div>
            )}
          </SSRMediaQuery>
        </div>
      </div>
      <div className={styles.actionContainer}>
        <SSRMediaQuery minWidth={BREAKPOINTS.lg}>
          <div
            data-testid="product-pod-prices-section"
            className={classNames(styles.prices, {
              [styles.unavailablePrice]: isUnavailable,
            })}
          >
            <div data-test="product-pod-price" className={styles.itemPriceSection}>
              <span className="sr-only">{TROLLEY_TEXT.itemPrice}</span>
              <Typography styleAs="sectionHeading" className={styles.price} fixedSize>
                <ProductPrice productId={productId} />
                {displayPriceEstimated && (
                  <span className={styles.priceEst}>
                    {` ${TROLLEY_TEXT.each}`}
                    <abbr title="estimated">{` ${TROLLEY_TEXT.estimated}`}</abbr>
                  </span>
                )}
              </Typography>
              {displayPriceQualifier && (
                <>
                  <span className="sr-only">{TROLLEY_TEXT.pricePer}</span>
                  <Typography styleAs="paragraph" className={styles.pricePerUnit}>
                    {/* removing parenthesis temporary */}
                    {displayPriceQualifier.split(/[()]/).join('')}
                  </Typography>
                </>
              )}
            </div>

            {isUnavailable && (
              <Typography styleAs="paragraphHeading" noMargins>
                {`${TROLLEY_TEXT.qty}: ${optimisticTrolleyQuantityAmount}${uomDisplay}`}
              </Typography>
            )}
          </div>
        </SSRMediaQuery>
        <PodButtons
          {...{
            isRemovable,
            isUnavailable,
            lineNumber,
            optimisticTrolleyQuantityAmount,
            productName,
            onClickAdd,
            onClickRemove,
          }}
        />
      </div>
    </li>
  );
};

SubProductPod.displayName = 'SubProductPod';

SubProductPod.propTypes = {
  lineNumber: PropTypes.string,
  isRemovable: PropTypes.bool,
  isUnavailable: PropTypes.bool,
  trolleyItemId: PropTypes.number,
};

SubProductPod.defaultProps = {
  lineNumber: null,
  isRemovable: false,
  isUnavailable: false,
  trolleyItemId: null,
};

export default memo(SubProductPod);
